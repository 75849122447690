<template>
	<v-container fluid class="full-height">
		<v-flex v-if="pricing">
			<div class="align-center" v-once>
				<v-card>
					<v-card-text>
						<center>
							<h1>
								<strong>
									Transparent Pricing
								</strong>
							</h1>
							<h3 class="mt-4">
								No Hidden Fees, No Extra Charges, No Contracts
							</h3>
						</center>
					</v-card-text>
				</v-card>
			</div>
			<div class="mt-8" v-once>
				<v-row>
					<v-col>
						<v-card>
							<v-card-title>
								Monthly Fees
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col align="start">
										<h2>
											${{ pricing.perUser.toFixed(2) }}
											<sub>
												/ User
											</sub>
										</h2>
										<span>
											Affordable pricing per agent. As compared to other
											ticketing providers, without transcription.
										</span>
										<h2 class="mt-2">
											${{ pricing.perMB.toFixed(2) }}
											<sub>
												/ Megabyte
											</sub>
										</h2>
										<span>
											In order to store your messages with high end security, we
											charge a small fee per megabyte.
										</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col>
						<v-card>
							<v-card-title>
								Per Voicemail Fees
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col align="start">
										<h2>
											${{ pricing.perTicket.toFixed(2) }}
											<sub>
												/ Ticket
											</sub>
										</h2>
										<span>
											With every ticket we access the latest caller ID
											information, to always know who's calling.
										</span>
										<h2 class="mt-2">
											${{ pricing.perMinute.toFixed(2) }}
											<sub>
												/ Minute
											</sub>
										</h2>
										<span>
											We charge what is costs us to transcribe the voicemail
											using AI driven speech recognition.
										</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</div>
			<div class="mt-8" v-once>
				<v-row>
					<v-col>
						<v-card>
							<v-card-title>
								Our Pricing Philosophy
							</v-card-title>
							<v-card-text>
								Software as a Service Platforms, are often very expensive.
								However, the telephony business is vastly different. We have a
								strong focus on cost reduction, and we are always looking for
								ways to reduce our costs. With TicketVM we continue on the
								tradition of fair pricing for excellent services. We offer a
								solution, that elegantly solves one of the largest problems in
								telephonic service: Voicemail. Providing the service at
								whole-sale pricing. Creating a tailored solution that is more
								cost effective than the alternatives.<sup>1</sup> Our goal is to
								make our customer's lives easier, and not to make them
								exorbitant fees.
							</v-card-text>
							<v-card-actions>
								<p style="font-size:8pt;color:rgba(0, 0, 0, 0.4)">
									1 - email based solutions start at $15/agent/month and does
									not transcribe voicemails
								</p>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</v-flex>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			pricing: null,
		};
	},
	async mounted() {
		// setTimeout(async () => {
		this.pricing = (await axios.get(`https://api.ticketvm.com/pricing`)).data;
	},
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
$windowSize: calc(100vh - 88px);
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	$windowSize: calc(100vh - 88px);
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
}
.full-height {
	min-height: $windowSize;
}
.vertical-center {
	margin: 0;
	position: absolute;
	top: 45%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
</style>
